// extracted by mini-css-extract-plugin
export var aiChat__aiPowered = "bt_lX";
export var aiChat__animate = "bt_lM";
export var aiChat__animate_content = "bt_lN";
export var aiChat__answer = "bt_lT";
export var aiChat__content = "bt_lQ";
export var aiChat__icon = "bt_lP";
export var aiChat__loadingState = "bt_lR";
export var aiChat__message = "bt_lL";
export var aiChat__question = "bt_lS";
export var aiChat__sender = "bt_lV";
export var aiChat__senderInfo = "bt_lW";
export var pulseSize = "bt_lh";