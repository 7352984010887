import React, { ChangeEvent, FormEvent, MouseEventHandler } from 'react'

import { RecommendedCategories } from '~/mock/ai-chat/RecommendedCategories'
import AIChatForm from '~/views/AIChat/components/AIChatForm'

import * as containerStyles from './AIChatPreview.module.scss'

interface AIChatPreviewProps {
  input: string
  isLoading: boolean
  handleSubmit: (e: FormEvent) => void
  handleChatStart: MouseEventHandler<HTMLButtonElement>
  handleInputChange: (e: ChangeEvent<HTMLTextAreaElement>) => void
  handleRecommendedQuestion: (buttonName: string) => void
}

const AIChatPreview = ({
  input,
  isLoading,
  handleSubmit,
  handleChatStart,
  handleInputChange,
  handleRecommendedQuestion,
}: AIChatPreviewProps) => (
  <div className={containerStyles.aiChat__preview}>
    <div className={containerStyles.aiChat__previewContainer}>
      <div>
        <div className={containerStyles.aiChat__previewTitle}>Hey there!</div>

        <div className={containerStyles.aiChat__previewDescr}>
          I am an AI assistant from Codica. I can answer your questions or
          connect you with the right person.
          <br />
          So, how can I help?
        </div>
      </div>

      <div className={containerStyles.aiChat__previewButtonWrapper}>
        {Object.keys(RecommendedCategories).map((buttonName, index) => (
          <button
            key={index}
            type="submit"
            name={buttonName}
            aria-label={buttonName}
            onClick={(e) => {
              handleChatStart(e)
              handleRecommendedQuestion(buttonName)
            }}
            className={containerStyles.aiChat__previewButton}
          >
            {buttonName}
          </button>
        ))}
      </div>
    </div>

    <div className={containerStyles.aiChat__previewFixedBottom}>
      <AIChatForm
        input={input}
        isLoading={isLoading}
        handleInputChange={handleInputChange}
        handleSubmit={(e) => {
          handleSubmit(e)
          handleChatStart(e as React.MouseEvent<HTMLButtonElement, MouseEvent>)
        }}
      />
    </div>
  </div>
)

export default AIChatPreview
